/* eslint-disable camelcase */
import { type UseQueryResult, useQuery } from 'react-query';
import { type TableParamsType } from '../../types/common';
import {
  type Abp_Application_Services_Dto_PagedResultDto_1OfOfWealthlane_TaxService_Dtos_TaxItemDto_GetTaxItemDto_Wealthlane_TaxService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null,
  TaxItemService,
} from '../../services/wealthlane-tax-services';

const useGetTaxSetupList = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>,
  tableInfo: TableParamsType
): UseQueryResult<
  | Abp_Application_Services_Dto_PagedResultDto_1OfOfWealthlane_TaxService_Dtos_TaxItemDto_GetTaxItemDto_Wealthlane_TaxService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null
  | undefined,
  unknown
> => {
  const {
    type: incomeYTDTypeId,
    taxPeriodId,
    taxApprovalStatusId,
    filter,
    sorting,
    skipCount,
    maxResultCount,
  } = tableInfo;
  const taxSetupList = useQuery(['getTaxSetupList', tableInfo], async () => {
    return await handleRequest(
      TaxItemService.getApiTaxApiAppTaxItemTaxItemList(
        taxPeriodId,
        taxApprovalStatusId,
        incomeYTDTypeId,
        filter,
        sorting,
        skipCount,
        maxResultCount
      )
    );
  });

  return taxSetupList;
};

export default useGetTaxSetupList;
