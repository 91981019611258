import { Form, Formik } from 'formik';
import InputText from '../../../components/input-text/inputText';
import ModalComponent from '../../../components/modal/modal';
import ModalBody from '../../../components/modal/modalBodyComponent';
import ModalFooterComponent from '../../../components/modal/modalFooterComponent';
import CustomTextArea from '../../../components/text-area/textArea';
import { usePostEmployeeTypes } from '../../../hooks';
import { type ModalStatusType } from '../../../types/common';
import { EMPLOYEE_TYPE_VALIDATION } from '../../../utils/validations/validations';
import _ from 'lodash';
import FormSelect from '../../../components/dropdown/formSelect';
import { YesNoOptions } from '../../../constants/common';
import CustomCheckBox from '../../../components/radio-button/customCheckBox';
import Heading from '../../../components/typography';

interface EmployeeTypeModalTypes {
  isModalOpen: boolean;
  closeModal: () => void;
  setModalStatus: (value: ModalStatusType) => void;
  initialData: {
    data: {
      name: string;
      description: string;
      isTaxEnable?: boolean;
    };
    id: string;
    modalStatus: ModalStatusType;
  };
  loading: boolean;
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>;
}

const EmployeeTypeModal = ({
  isModalOpen,
  closeModal,
  initialData,
  setModalStatus,
  loading,
  handleRequest,
}: EmployeeTypeModalTypes): JSX.Element => {
  const { mutate, isLoading } = usePostEmployeeTypes(handleRequest, closeModal, initialData.id);

  return (
    <Formik
      enableReinitialize
      initialValues={initialData.data}
      validationSchema={EMPLOYEE_TYPE_VALIDATION}
      onSubmit={(values) => {
        mutate(values);
      }}>
      {({ touched, handleChange, errors, values, setFieldValue }) => {
        return (
          <ModalComponent
            show={isModalOpen}
            closeModal={closeModal}
            loading={loading}
            size='md'
            title={`${initialData.modalStatus} Employee Type`}
            valueEdited={_.isEqual(values, initialData.data)}
            children={
              <Form>
                <ModalBody>
                  <InputText
                    type='text'
                    label='Name'
                    name='name'
                    onChange={handleChange}
                    errors={errors}
                    touched={touched}
                    inputType={initialData.modalStatus}
                    disabled={initialData.modalStatus === 'View'}
                    required
                  />
                  <CustomTextArea
                    label='Description'
                    rows='5'
                    name='description'
                    onChange={handleChange}
                    errors={errors}
                    touched={touched}
                    disabled={initialData.modalStatus === 'View'}
                  />

                  <CustomCheckBox
                    name='isTaxEnable'
                    onChange={(e: any) => {
                      handleChange(e);
                    }}
                    errors={errors}
                    touched={touched}
                    className={'individual-check mt-2'}
                    labelClassName={'whitespace-normal'}
                    disabled={initialData.modalStatus === 'View'}
                    checked={values?.isTaxEnable}
                    id='acceptCheck'
                    label={<Heading variant='body-xs' className='mt-1' title={'Include tax'} />}
                    required
                  />
                </ModalBody>
                <ModalFooterComponent
                  modalStatus={initialData.modalStatus}
                  onCloseModal={() => {
                    closeModal();
                  }}
                  onEditModal={() => {
                    setModalStatus('Edit');
                  }}
                  valueChanged={_.isEqual(values, initialData.data)}
                  loading={isLoading}
                />
              </Form>
            }
          />
        );
      }}
    </Formik>
  );
};

export default EmployeeTypeModal;
