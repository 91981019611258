import { isEmpty } from 'lodash';
import { ActionButton } from '../../components/vertical-dot/popper';
import { TableBodyCellColumn, TableBodyCellColumnCenter } from '../style/table.styled';
import Tag from '../../components/lozenge/tags';

export const employeeTypesHeader = (action: any): any[] => {
  return [
    {
      Header: 'Employee Types',
      accessor: 'name',
      width: 300,
      Cell: ({ row }: any) => {
        return <>{!isEmpty(row.original.name) ? row.original.name : '-'}</>;
      },
    },
    {
      Header: 'Description',
      accessor: 'description',
      width: 300,
      sortable: false,
      Cell: ({ row }: any) => {
        return (
          <TableBodyCellColumn>
            <div className='cellTitle '>
              {!isEmpty(row.original.description) ? row.original.description : '-'}
            </div>
          </TableBodyCellColumn>
        );
      },
    },
    {
      Header: 'Tax status',
      accessor: 'isTaxEnable',
      // width: 300,
      sortable: false,
      Cell: ({ row }: any) => (
        <div className='cellTitle'>
          <Tag
            variant={row.original.isTaxEnable === true ? 'success' : 'error'}
            title={row.original.isTaxEnable === true ? 'Enable' : 'Disable'}
          />
        </div>
      ),
    },
    {
      accessor: 'actions',
      width: 20,
      sortable: false,
      Cell: ({ row }: any) => {
        return (
          <ActionButton
            actions={action}
            id={row.original.id}
            rowData={row.original}
            counter={parseInt(row?.id)}
          />
        );
      },
    },
  ];
};
