import {
  TableContainer,
  TableSorting,
  TableStyle,
  TableTopContainer,
  TableWrapper,
} from './table.style';
import { useTable, usePagination, useSortBy } from 'react-table';

import { type LoadingType, type TableParamsType } from '../../types/common';
import { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import Heading from '../typography';
import FlashMessage from '../flash-message';
import { RadioButtonWrapper } from '../radio-button/radioButton.styled';
import PaginationComponent from './pagination';
import { SkeletonLine } from '../skeleton';
import moment from 'moment';
import Button from '../button';
import { COLORS } from '../../constants/style/color';
import CustomDropdownWithIcon from '../custom-dropdown/customDropdownWithIcon';

export interface TableType {
  columns: any;
  data: any;
  loading?: LoadingType;
  totalCounts: number;
  showPagination?: boolean;
  showPaginationList?: boolean;
  showRow?: boolean;
  pageOfIndex?: number;
  tableInfo: TableParamsType;
  setTableInfo?: any;
  containerClassName?: string;
  checkedValues?: string[];
  showCheckBox?: boolean;
  onChecked?: any;
  border?: string;
  padding?: number;
  checkboxFor?: string;
  coloredTable?: boolean;
  showColumn?: boolean;
  showBulkExport?: boolean;
  hasHeader?: boolean;
  isTableFor?: string;
  scrollableHeight?: number;
  tableColumnList?: any;
  selectedColumnList?: string[];
  handleTableColumn?: (columnValue: string) => void;
  handleExportBulk?: (value?: string) => void;
  handleSendInvite?: (value?: string) => void;
  handleColumnSave?: (value: string[]) => void;
  tablePreferenceLoading?: boolean;
  setIsHovering?: any;
  filteredData?: any;
  selectedTableData?: any;
  setSelectedTableData?: any;
  showSendInvite?: boolean;
}

const Table = ({
  columns,
  showPagination = false,
  data,
  loading,
  totalCounts,
  tableInfo,
  setTableInfo,
  containerClassName,
  showPaginationList = true,
  showRow = true,
  border,
  padding,
  checkedValues,
  showCheckBox = false,
  checkboxFor,
  onChecked,
  coloredTable = false,
  isTableFor,
  scrollableHeight,
  showColumn = false,
  showBulkExport = false,
  showSendInvite = false,
  tableColumnList,
  selectedColumnList,
  handleExportBulk,
  handleColumnSave,
  tablePreferenceLoading,
  setIsHovering,
  filteredData,
  selectedTableData,
  setSelectedTableData,
  handleSendInvite,
}: TableType): JSX.Element => {
  const [pageIndex, setPageIndex] = useState(1);
  const [pageSizeTotal, setPageSizeTotal] = useState(10);
  const [columnHeader, setColumnHeader] = useState(selectedColumnList);
  const [sortField, setSortField] = useState('');
  const [order, setOrder] = useState('');
  const handleMouseOver = (id: string): void => {
    setIsHovering(id);
  };

  useEffect(() => {
    if (tableInfo?.sorting === '') {
      setOrder('');
    }
  }, [tableInfo?.sorting]);

  const handleMouseOut = (): void => {
    setIsHovering('');
  };

  const { getTableProps, getTableBodyProps, headerGroups, page, prepareRow, setPageSize, state } =
    useTable(
      {
        columns,
        data,
        initialState: { pageSize: totalCounts },
      },
      useSortBy,
      usePagination
    );

  useEffect(() => {
    setColumnHeader(selectedColumnList);
  }, [selectedColumnList]);
  useEffect(() => {
    if (tableInfo.skipCount === 0) {
      setPageIndex(1);
    }
    if (tableInfo.maxResultCount !== 0 && tableInfo.maxResultCount != null) {
      setPageSizeTotal(tableInfo.maxResultCount);
    }
  }, [tableInfo]);

  useEffect(() => {
    if (!showPagination || !showPaginationList) {
      setPageSize(totalCounts);
    }
  }, [showPagination, totalCounts, showPaginationList, isTableFor]);
  const capitalizeFirst = (str: string): string => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const handleColumn = (columnValue: string): void => {
    let filterList = columnHeader != null ? [...columnHeader] : [];
    if (filterList.includes(columnValue)) {
      filterList = filterList.filter((item) => item !== columnValue);
    } else {
      filterList.push(columnValue);
    }
    setColumnHeader(filterList);
  };

  const handleSortingChange = (accessor: any, sortable: boolean = true): void => {
    const sortingTitle = capitalizeFirst(accessor);
    const sortOrder =
      accessor === sortField && order === 'Asc'
        ? 'Desc'
        : accessor === sortField && order === 'Desc'
        ? ''
        : 'Asc';
    setSortField(accessor);

    sortable &&
      setTableInfo({
        ...tableInfo,
        sorting: isEmpty(sortOrder) ? '' : `${sortingTitle} ${sortOrder}`,
      });
    setOrder(sortOrder);
  };

  // Checkbox function
  const [checkedKeys, setCheckedKeys] = useState<Array<string | number>>([]);
  let checked = false;

  useEffect(() => {
    if (!isEmpty(checkedValues) && isEmpty(checkedKeys)) {
      setCheckedKeys(checkedValues ?? []);
    }
    if (checkedValues !== checkedKeys) {
      setCheckedKeys(checkedValues ?? []);
    }
    if (isEmpty(checkedValues)) {
      setCheckedKeys([]);
    }
  }, [checkedValues]);

  useEffect(() => {
    if (loading === 'success' && data.length === 0 && tableInfo.skipCount > 0) {
      setPageIndex(tableInfo.skipCount / 10);
      setTableInfo({ ...tableInfo, skipCount: tableInfo.skipCount - 10 });
    }
  }, [data, loading]);

  const PendingData = data?.filter((item: any) => item.status === 'Pending');
  const ApprovedData = data?.filter((item: any) => item.status === 'Approved');
  const DownloadableData = data?.filter((item: any) => item.isDownloadable);
  if (checkedKeys.length === data?.length && checkedKeys.length !== 0) {
    checked = true;
  }
  if (filteredData && checkedKeys.length === filteredData?.length && checkedKeys.length !== 0) {
    checked = true;
  }

  if (
    checkboxFor === 'Pending' &&
    checkedKeys.length === PendingData?.length &&
    checkedKeys.length !== 0
  ) {
    checked = true;
  }
  if (
    checkboxFor === 'Approved' &&
    checkedKeys.length === ApprovedData?.length &&
    checkedKeys.length !== 0
  ) {
    checked = true;
  }
  if (
    checkboxFor === 'Downloadable' &&
    checkedKeys.length === DownloadableData?.length &&
    checkedKeys.length !== 0
  ) {
    checked = true;
  } else if (checkedKeys.length === 0) {
    checked = false;
  }

  const handleCheckAll = (checked: boolean): void => {
    const keys = checked
      ? !isEmpty(checkedKeys)
        ? []
        : filteredData !== undefined
        ? filteredData.map((items: any) => items.id)
        : checkboxFor === 'Pending'
        ? PendingData.map((items: any) => items.id)
        : checkboxFor === 'Approved' &&
          (isTableFor === 'RsuAwards' || isTableFor === 'OptionAwards')
        ? ApprovedData.filter((data: any) => data?.isEmailNotificationSent === false).map(
            (e: any) => e.id
          )
        : isTableFor === 'Release'
        ? data.map((items: any) => items.id)
        : isTableFor === 'Exercise'
        ? data.map((items: any) => items.id)
        : isTableFor === 'TransferAndAllocate'
        ? data.map((items: any) => items.id)
        : checkboxFor === 'Approved'
        ? ApprovedData.map((items: any) => items.id) != null
          ? data.map((item: any) => item.id)
          : []
        : checkboxFor === 'Downloadable'
        ? DownloadableData.map((items: any) => items.id)
        : data.map((items: any) => items.id)
      : [];

    const value =
      checked &&
      (isTableFor === 'Release' ||
        isTableFor === 'TransferAndAllocate' ||
        isTableFor === 'Exercise')
        ? data.map((items: any) => items)
        : [];
    setCheckedKeys(keys);
    setSelectedTableData && setSelectedTableData(value);
    showCheckBox && Boolean(onChecked) && onChecked(keys);
  };

  const handleCheck = (value: string, checked: boolean): void => {
    const keys: any = checked
      ? [...checkedKeys, value]
      : checkedKeys.filter((item) => item !== value);

    setCheckedKeys(keys);
    showCheckBox && Boolean(onChecked) && onChecked(keys);
  };
  const handleCheckedValue = (value: string, checked: boolean, selectedValue: any): void => {
    const keys: any = checked
      ? [...checkedKeys, value]
      : checkedKeys.filter((item) => item !== value);

    const newSelectedValue: any =
      selectedTableData && checked
        ? [...selectedTableData, selectedValue]
        : selectedTableData?.filter((item: any) => item?.id !== selectedValue?.id);
    setCheckedKeys(keys);
    if (isTableFor === 'Release') {
      setSelectedTableData(
        newSelectedValue.map((data: any) => {
          return { ...data, rsuVestingId: data?.id };
        })
      );
    }
    if (isTableFor === 'Exercise') {
      setSelectedTableData(
        newSelectedValue.map((data: any) => {
          return { ...data, rsuVestingId: data?.id };
        })
      );
    }
    if (isTableFor === 'TransferAndAllocate') {
      setSelectedTableData(
        newSelectedValue.map((data: any) => {
          return { ...data };
        })
      );
    }
    showCheckBox && Boolean(onChecked) && onChecked(keys);
  };
  const checkTableBodyHeight =
    loading === 'loading'
      ? 'auto'
      : data.length === 0
      ? '213px'
      : Boolean(data) && data.length < 6
      ? `${data.length * 87 + 81}px`
      : `${scrollableHeight ?? 500}px`;
  return (
    <TableWrapper border={border}>
      {(showColumn || showBulkExport) && (
        <TableTopContainer>
          {showColumn && (
            <CustomDropdownWithIcon
              data={tableColumnList ?? []}
              handleUser={(columnValue) => {
                handleColumn(columnValue);
              }}
              checkedList={columnHeader ?? []}
              handleSubmit={() => {
                handleColumnSave != null && columnHeader != null && handleColumnSave(columnHeader);
              }}
              loading={tablePreferenceLoading}
              width='255px'
              padding='0px'
            />
          )}
          {/* {showBulkExport && DownloadableData.length > 0 && (
            <Button
              id='dropdownId'
              title='Bulk Export XLS'
              type='button'
              variant='secondary-gray'
              onClick={() => {
                handleExportBulk?.();
              }}
            />
          )} */}
          {showBulkExport && checkedValues !== undefined && checkedValues?.length > 0 && (
            <Button
              id='dropdownId'
              title='Bulk Export XLS'
              type='button'
              variant='secondary-gray'
              onClick={() => {
                handleExportBulk?.();
              }}
            />
          )}
          {showSendInvite && checkedValues !== undefined && checkedValues?.length > 0 && (
            <Button
              id='dropdownId'
              title='Sent Invite'
              type='button'
              variant='secondary'
              onClick={() => {
                handleSendInvite?.();
              }}
            />
          )}
        </TableTopContainer>
      )}
      <TableContainer padding={padding} height={checkTableBodyHeight}>
        <TableStyle {...getTableProps} className={containerClassName}>
          <thead>
            {
              // Loop over the header rows
              headerGroups.map((headerGroup: any) => {
                return (
                  // Apply the header row props
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {
                      // Loop over the headers in each row
                      <>
                        {showCheckBox && (
                          <th style={{ minWidth: '10px', width: '10px' }}>
                            <RadioButtonWrapper
                              label='label'
                              className={`${
                                (filteredData !== undefined &&
                                  checkedKeys.length < filteredData?.length &&
                                  checkedKeys.length > 0) ||
                                (checkboxFor === 'Approved' &&
                                  checkedKeys.length < ApprovedData?.length &&
                                  checkedKeys.length > 0) ||
                                (checkboxFor === 'Pending' &&
                                  checkedKeys.length < PendingData?.length &&
                                  checkedKeys.length > 0) ||
                                (checkboxFor === 'Downloadable' &&
                                  checkedKeys.length < DownloadableData?.length &&
                                  checkedKeys.length > 0) ||
                                (isTableFor === 'Participant' &&
                                  checkedKeys.length < data?.length &&
                                  checkedKeys.length > 0) ||
                                (checkedKeys?.length < data?.length && checkedKeys?.length > 0)
                                  ? 'checkboxWrapper block partialSelect'
                                  : 'checkboxWrapper block'
                              }  `}>
                              <input
                                type='checkbox'
                                name={'row.index'}
                                checked={checked}
                                onChange={(event: any) => {
                                  handleCheckAll(event.target.checked);
                                }}
                              />
                            </RadioButtonWrapper>
                          </th>
                        )}
                        {headerGroup.headers.map(
                          (column: {
                            id: string;
                            sortable?: boolean | undefined;
                            getHeaderProps: any;
                            manualSorting: boolean;
                            getSortByToggleProps: any;
                            width: number;
                            center?: boolean;
                            isSorted: boolean;
                            isSortedDesc: boolean | undefined;
                            Header: string;
                          }) => {
                            return (
                              <th
                                key={column.id}
                                onClick={() => {
                                  data?.length > 0
                                    ? handleSortingChange(column.id, column?.sortable)
                                    : null;
                                }}
                                {...column.getHeaderProps(
                                  Boolean(column?.manualSorting) && column.getSortByToggleProps(),
                                  {
                                    style: {
                                      width: column.width,
                                    },
                                  }
                                )}>
                                <TableSorting
                                  className={`flex items-start custom-header--main justify-${
                                    column.center ?? false ? 'center' : 'start'
                                  }`}>
                                  <Heading variant='body-s' title={column.Header} />
                                  {column.sortable !== false && (
                                    <div className='flex flex-col sorting-icon'>
                                      <span
                                        className={`material-symbols-rounded 
                                      ${
                                        (column.id === sortField && order === 'Asc') ||
                                        (column.isSorted && !(column.isSortedDesc ?? false))
                                          ? 'active'
                                          : ''
                                      }`}>
                                        arrow_drop_up
                                      </span>
                                      <span
                                        className={`material-symbols-rounded 
                                      ${
                                        (column.id === sortField && order === 'Desc') ||
                                        (column.isSortedDesc ?? false)
                                          ? 'active'
                                          : ''
                                      }`}>
                                        arrow_drop_down
                                      </span>
                                    </div>
                                  )}
                                </TableSorting>
                              </th>
                            );
                          }
                        )}
                      </>
                    }
                  </tr>
                );
              })
            }
          </thead>
          <tbody {...getTableBodyProps()}>
            {loading === 'loading' && (
              <>
                {Array.from(Array(6).keys()).map((item) => (
                  <>
                    {headerGroups.map((headerGroup: any) => {
                      return (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                          {
                            <>
                              {showCheckBox && (
                                <td>
                                  <SkeletonLine size='table' width={'20px'} />
                                </td>
                              )}
                              {headerGroup.headers.map((column: any) => {
                                return (
                                  <td>
                                    <SkeletonLine
                                      size='table'
                                      width={`${String(column.width ?? '')}px`}
                                    />
                                  </td>
                                );
                              })}
                            </>
                          }
                        </tr>
                      );
                    })}
                  </>
                ))}
              </>
            )}
            {loading === 'success' &&
              page.map((row: any, i: number) => {
                prepareRow(row);
                return (
                  <>
                    <tr
                      style={{
                        background:
                          coloredTable &&
                          (row?.original?.isExpired === true
                            ? `${COLORS.CulturedWhite}`
                            : new Date() >= moment(row?.original?.vestingDate).toDate()
                            ? `#F0F2FF`
                            : `#E5EFE8`),
                      }}
                      {...row.getRowProps()}
                      onMouseOver={() => {
                        setIsHovering != null && handleMouseOver(row?.id);
                      }}
                      onMouseOut={() => {
                        setIsHovering != null && handleMouseOut();
                      }}>
                      {showCheckBox && (
                        <>
                          <td style={{ minWidth: '10px', width: '10px', paddingLeft: '3px' }}>
                            {filteredData !== undefined ? (
                              filteredData
                                ?.map((item: any) => item?.id)
                                ?.includes(row.original.id) ? (
                                <RadioButtonWrapper label='label' className='checkboxWrapper block'>
                                  <input
                                    type='checkbox'
                                    value={row.original.id}
                                    checked={checkedKeys.some(
                                      (item: any) => item === row.original.id
                                    )}
                                    onChange={(event: any) => {
                                      handleCheck(row.original.id, event.target.checked);
                                    }}
                                  />
                                </RadioButtonWrapper>
                              ) : null
                            ) : checkboxFor === 'Pending' ? (
                              row.original.status === 'Pending' ? (
                                <RadioButtonWrapper label='label' className='checkboxWrapper block'>
                                  <input
                                    type='checkbox'
                                    value={row.original.id}
                                    checked={checkedKeys.some(
                                      (item: any) => item === row.original.id
                                    )}
                                    onChange={(event: any) => {
                                      handleCheck(row.original.id, event.target.checked);
                                    }}
                                  />
                                </RadioButtonWrapper>
                              ) : null
                            ) : checkboxFor === 'Downloadable' ? (
                              row.original.isDownloadable != null &&
                              row.original.isDownloadable !== false ? (
                                <RadioButtonWrapper label='label' className='checkboxWrapper block'>
                                  <input
                                    type='checkbox'
                                    value={row.original.id}
                                    checked={checkedKeys.some(
                                      (item: any) => item === row.original.id
                                    )}
                                    onChange={(event: any) => {
                                      handleCheck(row.original.id, event.target.checked);
                                    }}
                                  />
                                </RadioButtonWrapper>
                              ) : null
                            ) : checkboxFor === 'Approved' ? (
                              row.original.status === 'Approved' ? (
                                row.original.isEmailNotificationSent !== false ? (
                                  row.original.isEmailNotificationSent === false && (
                                    <RadioButtonWrapper
                                      label='label'
                                      className='checkboxWrapper block'>
                                      <input
                                        type='checkbox'
                                        value={row.original.id}
                                        checked={checkedKeys.some(
                                          (item: any) => item === row.original.id
                                        )}
                                        onChange={(event: any) => {
                                          handleCheck(row.original.id, event.target.checked);
                                        }}
                                      />
                                    </RadioButtonWrapper>
                                  )
                                ) : (
                                  <RadioButtonWrapper
                                    label='label'
                                    className='checkboxWrapper block'>
                                    <input
                                      type='checkbox'
                                      value={row.original.id}
                                      checked={checkedKeys.some(
                                        (item: any) => item === row.original.id
                                      )}
                                      onChange={(event: any) => {
                                        handleCheck(row.original.id, event.target.checked);
                                      }}
                                    />
                                  </RadioButtonWrapper>
                                )
                              ) : null
                            ) : isTableFor === 'Exercise' ? (
                              row.original.recordTypeSystemName === 'OptionsExercise' ||
                              (row.original.recordTypeSystemName === 'OptionIntent' &&
                                row.original.settlementTypeSystemName === 'Cash') ? (
                                <RadioButtonWrapper label='label' className='checkboxWrapper block'>
                                  <input
                                    type='checkbox'
                                    value={row.original.id}
                                    checked={checkedKeys.some(
                                      (item: any) => item === row.original.id
                                    )}
                                    onChange={(event: any) => {
                                      handleCheckedValue(
                                        row.original.id,
                                        event.target.checked,
                                        row.original
                                      );
                                    }}
                                  />
                                </RadioButtonWrapper>
                              ) : null
                            ) : isTableFor === 'Release' || 'TransferAndAllocate' ? (
                              <RadioButtonWrapper label='label' className='checkboxWrapper block'>
                                <input
                                  type='checkbox'
                                  value={row.original.id}
                                  checked={checkedKeys.some(
                                    (item: any) => item === row.original.id
                                  )}
                                  onChange={(event: any) => {
                                    handleCheckedValue(
                                      row.original.id,
                                      event.target.checked,
                                      row.original
                                    );
                                  }}
                                />
                              </RadioButtonWrapper>
                            ) : (
                              <RadioButtonWrapper label='label' className='checkboxWrapper block'>
                                <input
                                  type='checkbox'
                                  value={row.original.id}
                                  checked={checkedKeys.some(
                                    (item: any) => item === row.original.id
                                  )}
                                  onChange={(event: any) => {
                                    handleCheck(row.original.id, event.target.checked);
                                  }}
                                />
                              </RadioButtonWrapper>
                            )}
                          </td>
                        </>
                      )}
                      {row.cells.map((cell: any) => {
                        return (
                          <td
                            {...cell.getCellProps({
                              style: {
                                minWidth: cell.column.minWidth,
                                width: cell.column.width,
                              },
                            })}>
                            {cell.render('Cell')}
                          </td>
                        );
                      })}
                    </tr>
                  </>
                );
              })}
            {loading === 'success' && Boolean(page) && page.length === 0 && (
              <tr className='border-0'>
                <td colSpan={100} className='border-b-0'>
                  <div className='flex justify-center'>
                    <FlashMessage size='md' message='No Data Found' type='info' />
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </TableStyle>
      </TableContainer>

      {showPagination && data.length > 0 && (
        <>
          <PaginationComponent
            data={data}
            totalCount={totalCounts}
            pageIndex={pageIndex}
            showRow={showRow}
            pageSize={state.pageSize}
            showPaginationList={showPaginationList}
            maxResultCount={tableInfo.maxResultCount}
            pageSizeTotal={pageSizeTotal}
            handlePaginationInfo={(value: number) => {
              setTableInfo({
                ...tableInfo,
                skipCount: (value - 1) * tableInfo.maxResultCount,
              });
              setPageIndex(value);
            }}
            handleSelectInfo={(value: string) => {
              setTableInfo({
                ...tableInfo,
                skipCount: 0,
                maxResultCount: Number(value),
              });
              setPageSizeTotal(Number(value));
              setPageSize(Number(value));
            }}
          />
        </>
      )}
    </TableWrapper>
  );
};

export default Table;
