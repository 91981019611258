/* eslint-disable camelcase */
import { toast } from 'react-toastify';
import { type UseMutationResult, useMutation, useQueryClient } from 'react-query';
import ToastComponent from '../../components/toast';
import {
  OptionExerciseService,
  type Wealthlane_AwardService_Dtos_Response_ResponseDto,
} from '../../services/wealthlane-award-services';

const usePostFillFundedAmountForCashExcercise = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>,
  closeConfirmChangeModal: () => void,
  closeModal: () => void
): UseMutationResult<
  Wealthlane_AwardService_Dtos_Response_ResponseDto | undefined,
  unknown,
  any,
  unknown
> => {
  const queryClient = useQueryClient();
  return useMutation(
    async (payload: any) => {
      return await handleRequest(
        OptionExerciseService.postApiAwardApiAppOptionExerciseFillFundedAmountForCashExcercise(
          payload
        )
      );
    },
    {
      onSuccess: async (
        response: Wealthlane_AwardService_Dtos_Response_ResponseDto | undefined
      ) => {
        if (response?.success) {
          toast.success(<ToastComponent label='Success' message='Funded Amount Changed.' />);
          closeConfirmChangeModal();
          closeModal();
          await queryClient.invalidateQueries('getOptionExercisedList');
        } else {
          closeConfirmChangeModal();
        }
      },
      onError: (err) => {
        console.log('error', err);
        closeConfirmChangeModal();
      },
    }
  );
};

export default usePostFillFundedAmountForCashExcercise;
