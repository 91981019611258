/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_TaxService_Dtos_TaxPeriod_TaxPeriodDto_Wealthlane_TaxService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null } from '../models/Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_TaxService_Dtos_TaxPeriod_TaxPeriodDto_Wealthlane_TaxService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null';
import type { Wealthlane_TaxService_Dtos_Response_ResponseDto } from '../models/Wealthlane_TaxService_Dtos_Response_ResponseDto';
import type { Wealthlane_TaxService_Dtos_TaxPeriod_CreateTaxPeriodDto } from '../models/Wealthlane_TaxService_Dtos_TaxPeriod_CreateTaxPeriodDto';
import type { Wealthlane_TaxService_Dtos_TaxPeriod_TaxPeriodDto } from '../models/Wealthlane_TaxService_Dtos_TaxPeriod_TaxPeriodDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class TaxPeriodService {

    /**
     * @param requestBody 
     * @returns Wealthlane_TaxService_Dtos_Response_ResponseDto Success
     * @throws ApiError
     */
    public static postApiTaxApiAppTaxPeriodTaxPeriod(
requestBody?: Wealthlane_TaxService_Dtos_TaxPeriod_CreateTaxPeriodDto,
): CancelablePromise<Wealthlane_TaxService_Dtos_Response_ResponseDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/tax/api/app/tax-period/tax-period',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns Wealthlane_TaxService_Dtos_Response_ResponseDto Success
     * @throws ApiError
     */
    public static putApiTaxApiAppTaxPeriodTaxPeriod(
requestBody?: Wealthlane_TaxService_Dtos_TaxPeriod_TaxPeriodDto,
): CancelablePromise<Wealthlane_TaxService_Dtos_Response_ResponseDto> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/tax/api/app/tax-period/tax-period',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns Wealthlane_TaxService_Dtos_TaxPeriod_TaxPeriodDto Success
     * @throws ApiError
     */
    public static getApiTaxApiAppTaxPeriodTaxPeriod(): CancelablePromise<Array<Wealthlane_TaxService_Dtos_TaxPeriod_TaxPeriodDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/tax/api/app/tax-period/tax-period',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns Wealthlane_TaxService_Dtos_TaxPeriod_TaxPeriodDto Success
     * @throws ApiError
     */
    public static getApiTaxApiAppTaxPeriodTaxPeriodApprover(): CancelablePromise<Array<Wealthlane_TaxService_Dtos_TaxPeriod_TaxPeriodDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/tax/api/app/tax-period/tax-period-approver',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param taxPeriodId 
     * @param taxApprovalStatusId 
     * @param incomeYtdTypeId 
     * @param filter 
     * @param sorting 
     * @param skipCount 
     * @param maxResultCount 
     * @returns Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_TaxService_Dtos_TaxPeriod_TaxPeriodDto_Wealthlane_TaxService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null Success
     * @throws ApiError
     */
    public static getApiTaxApiAppTaxPeriodPagedSortedList(
taxPeriodId?: string,
taxApprovalStatusId?: number,
incomeYtdTypeId?: string,
filter?: string,
sorting?: string,
skipCount?: number,
maxResultCount?: number,
): CancelablePromise<Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_TaxService_Dtos_TaxPeriod_TaxPeriodDto_Wealthlane_TaxService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/tax/api/app/tax-period/paged-sorted-list',
            query: {
                'TaxPeriodId': taxPeriodId,
                'TaxApprovalStatusId': taxApprovalStatusId,
                'IncomeYTDTypeId': incomeYtdTypeId,
                'Filter': filter,
                'Sorting': sorting,
                'SkipCount': skipCount,
                'MaxResultCount': maxResultCount,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns Wealthlane_TaxService_Dtos_Response_ResponseDto Success
     * @throws ApiError
     */
    public static postApiTaxApiAppTaxPeriodTaxPeriodForVendor(
requestBody?: Wealthlane_TaxService_Dtos_TaxPeriod_CreateTaxPeriodDto,
): CancelablePromise<Wealthlane_TaxService_Dtos_Response_ResponseDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/tax/api/app/tax-period/tax-period-for-vendor',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns Wealthlane_TaxService_Dtos_Response_ResponseDto Success
     * @throws ApiError
     */
    public static putApiTaxApiAppTaxPeriodTaxPeriodForVendor(
requestBody?: Wealthlane_TaxService_Dtos_TaxPeriod_TaxPeriodDto,
): CancelablePromise<Wealthlane_TaxService_Dtos_Response_ResponseDto> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/tax/api/app/tax-period/tax-period-for-vendor',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns Wealthlane_TaxService_Dtos_TaxPeriod_TaxPeriodDto Success
     * @throws ApiError
     */
    public static getApiTaxApiAppTaxPeriodTaxPeriodForVendor(): CancelablePromise<Array<Wealthlane_TaxService_Dtos_TaxPeriod_TaxPeriodDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/tax/api/app/tax-period/tax-period-for-vendor',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns Wealthlane_TaxService_Dtos_TaxPeriod_TaxPeriodDto Success
     * @throws ApiError
     */
    public static getApiTaxApiAppTaxPeriodTaxPeriodForVendorApprover(): CancelablePromise<Array<Wealthlane_TaxService_Dtos_TaxPeriod_TaxPeriodDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/tax/api/app/tax-period/tax-period-for-vendor-approver',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param taxPeriodId 
     * @param taxApprovalStatusId 
     * @param incomeYtdTypeId 
     * @param filter 
     * @param sorting 
     * @param skipCount 
     * @param maxResultCount 
     * @returns Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_TaxService_Dtos_TaxPeriod_TaxPeriodDto_Wealthlane_TaxService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null Success
     * @throws ApiError
     */
    public static getApiTaxApiAppTaxPeriodPagedSortedListForVendor(
taxPeriodId?: string,
taxApprovalStatusId?: number,
incomeYtdTypeId?: string,
filter?: string,
sorting?: string,
skipCount?: number,
maxResultCount?: number,
): CancelablePromise<Volo_Abp_Application_Dtos_PagedResultDto_1OfOfWealthlane_TaxService_Dtos_TaxPeriod_TaxPeriodDto_Wealthlane_TaxService_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/tax/api/app/tax-period/paged-sorted-list-for-vendor',
            query: {
                'TaxPeriodId': taxPeriodId,
                'TaxApprovalStatusId': taxApprovalStatusId,
                'IncomeYTDTypeId': incomeYtdTypeId,
                'Filter': filter,
                'Sorting': sorting,
                'SkipCount': skipCount,
                'MaxResultCount': maxResultCount,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

}
