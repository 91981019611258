/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CustomerImportSampleDownloadResponse } from '../models/CustomerImportSampleDownloadResponse';
import type { Wealthlane_CustomerService_Dtos_ResponseDto_1OfOfSystem_String_System_Private_CoreLib_Version_7_0_0_0_Culture_neutral_PublicKeyToken_7cec85d7bea7798e } from '../models/Wealthlane_CustomerService_Dtos_ResponseDto_1OfOfSystem_String_System_Private_CoreLib_Version_7_0_0_0_Culture_neutral_PublicKeyToken_7cec85d7bea7798e';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class CustomerTitleImportService {

    /**
     * @param formData 
     * @returns Wealthlane_CustomerService_Dtos_ResponseDto_1OfOfSystem_String_System_Private_CoreLib_Version_7_0_0_0_Culture_neutral_PublicKeyToken_7cec85d7bea7798e Success
     * @throws ApiError
     */
    public static postApiCustomerApiAppCustomerTitleImportImportCustomerTitle(
formData?: {
ImportFile?: Blob;
},
): CancelablePromise<Wealthlane_CustomerService_Dtos_ResponseDto_1OfOfSystem_String_System_Private_CoreLib_Version_7_0_0_0_Culture_neutral_PublicKeyToken_7cec85d7bea7798e> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/customer/api/app/customer-title-import/import-customer-title',
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns CustomerImportSampleDownloadResponse Success
     * @throws ApiError
     */
    public static postApiCustomerApiAppCustomerTitleImportDownloadCustomerTitleImportSample(): CancelablePromise<CustomerImportSampleDownloadResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/customer/api/app/customer-title-import/download-customer-title-import-sample',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

}
