
interface ChatbotType {
  isChatModalOpen: boolean;
  openChatModal: () => void;
}

const Chatbot = ({
  isChatModalOpen,
  openChatModal,
}: ChatbotType): JSX.Element => {
  return (
    <button
    style={{
      border: '1px solid #DCDCDC', // Gainsboro color for the border
      padding: '5px 20px', // Adjust padding as needed
      borderRadius: '5px', // Rounded corners for the border
      // Add other styles as needed
    }}
    onClick={() => {
      openChatModal();
    }}
  >
    Chat
  </button>

  );
};

export default Chatbot;
