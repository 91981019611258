import React, { useState, useEffect, ReactNode } from 'react';
import {
  useGetPSUElectionWindowConfiguration,
  useGetSettlementType,
  useWealthlaneAwardApi,
  useWealthlaneAwardSettlementApi,
} from '../../../../../hooks';
import { ElectionCardBlock, ElectionWindowWrapper } from './electionWindow.styled';
import Heading from '../../../../../components/typography';
import ClickableTooltip from '../../../../../components/tooltip/clickTooltip';
import { MaterialIconFilled, checkNaNValue } from '../../../../../utils/global';
import { Form, Formik } from 'formik';
import InputText from '../../../../../components/input-text/inputText';
import Button from '../../../../../components/button';
import ElectionCustomDropdown from '../../../../../components/custom-dropdown/electionDropdown';
import { PsuElectionWindowConfigurationService } from '../../../../../services/wealthlane-award-settlement-services';
import Tag from '../../../../../components/lozenge/tags';
import { DropdownType } from '../../../../../types/common';
import { useMutation, useQueryClient } from 'react-query';
import { ELECTION_CONFIGURATION } from '../../../../../utils/validations/validations';
import { toast } from 'react-toastify';
import ToastComponent from '../../../../../components/toast';
import _, { isEmpty } from 'lodash';
import Spinners from '../../../../../components/spinner';

interface PsuElectionType {
  refresher: boolean;
}

const PsuElectionConfig = ({ refresher }: PsuElectionType) => {
  const queryClient = useQueryClient();
  const { handleRequest } = useWealthlaneAwardSettlementApi();
  const { handleRequest: awardHandleRequest } = useWealthlaneAwardApi();
  const [checkedList, setCheckedList] = useState<string[]>([]);
  const { data: psuConfig, isLoading: psuLoader } =
    useGetPSUElectionWindowConfiguration(handleRequest);
  const [defaultPsuValue, setDefaultPsuValue] = useState<string>('');
  const [viewSelected, setViewSelected] = useState<boolean>(false);
  const [apiData, setApiData] = useState<string[]>([]);
  const { data: settlementTypeOption } = useGetSettlementType(awardHandleRequest);

  // const initialOptions = [
  //   { label: 'Sell All', title: 'Sell All', value: 'sellAll' },
  //   { label: 'Sell to Cover', title: 'Sell to Cover', value: 'sellToCover' },
  //   { label: 'Withhold to Cover', title: 'Withhold to Cover', value: 'holdToCover' },
  // ];
  // const initialOptions = [
  //   {
  //     value: '303a517c-2098-46d4-adec-594e605d374c',
  //     systemName: 'Sell To Cover',
  //     label: 'Sell To Cover',
  //   },
  //   {
  //     value: '62c986d1-3c75-429b-8967-f07a74ff5e66',
  //     systemName: 'Sell All',
  //     label: 'Sell All',
  //   },
  //   {
  //     value: 'bf927847-3f00-4374-9ea6-78e7fad766c1',
  //     systemName: 'Withhold To Cover',
  //     label: 'Withhold To Cover',
  //   },
  //   {
  //     value: 'b54f1ac5-95a4-4083-8c5c-c12733672402',
  //     systemName: 'Cash',
  //     label: 'Cash',
  //   },
  // ];
  const [initialOptions, setInitialOptions] = useState<any>(settlementTypeOption);

  useEffect(() => {
    if (settlementTypeOption !== null && settlementTypeOption !== undefined) {
      setInitialOptions(settlementTypeOption);
    }
  }, [settlementTypeOption]);

  const [electionMethod, setElectionMethod] = useState<DropdownType[]>(initialOptions);

  useEffect(() => {
    setApiData(
      psuConfig != null
        ? psuConfig?.settlementTypeIds.map((item: any) => item?.settlementTypeId)
        : []
    );
  }, [psuConfig, electionMethod]);

  useEffect(() => {
    apiData?.length > 0 && setViewSelected(true);
    !apiData?.includes(defaultPsuValue)
      ? setCheckedList([...apiData, defaultPsuValue])
      : setCheckedList(apiData);
  }, [apiData]);

  const getDefaultPsu = (): void => {
    handleRequest(
      PsuElectionWindowConfigurationService.getApiAwardSettlementApiAppPSUElectionWindowConfigurationDefaultSettlementForPSu()
    )
      .then((response: any) => {
        if (response != null) {
          const respDefault = Object.keys({ ...response[0] })
            .filter((k) => response[0][k] === true)
            .toString();
          setDefaultPsuValue(response[0]?.settlementTypeId);

          const filterMethodOptions = initialOptions
            ?.filter((item: any) => item.value === response[0]?.settlementTypeId)
            .map((data: any) => ({
              ...data,
              label: (
                <div className='flex flex-row gap-x-3'>
                  {data?.label} <Tag variant='default' title='Default' />
                </div>
              ),
            }))
            .concat(
              initialOptions?.filter((item: any) => item.value !== response[0]?.settlementTypeId)
            );

          setElectionMethod(filterMethodOptions);
        }
      })
      .catch((error: any) => {
        console.log('error', error);
      });
  };

  const handleCheck = (value: string, setField: any): void => {
    setField('settlementValidity', isEmpty(checkedList) ? true : viewSelected);
    setViewSelected(true);
    const keys =
      value !== defaultPsuValue
        ? checkedList?.includes(value)
          ? checkedList.filter((item) => item !== value)
          : [...checkedList, value]
        : checkedList;
    setCheckedList(keys);
  };

  const transformObjectToNull = (values: any): any => {
    const obj: any = {};
    Object.entries(values)?.forEach(([key, value]) => {
      // We have to convert the values of object which contains boolean value only
      obj[key] = typeof value === 'number' && value === 0 ? null : value;
    });
    obj.settlementValidity = 'invalid';
    return obj;
  };

  const usePostPsuElectionConfig = (): any => {
    return useMutation(
      async (payload: any) => {
        return await handleRequest(
          PsuElectionWindowConfigurationService.postApiAwardSettlementApiAppPSUElectionWindowConfigurationSavePSuElectionWindowConfiguration(
            payload
          )
        );
      },
      {
        onSuccess: (response: any) => {
          if (response != null) {
            toast.success(<ToastComponent label='Success' message={'Configuration saved'} />);
            void queryClient.invalidateQueries('getPSUElectionWindowConfigurationList');
          }
        },
        onError: (err) => {
          console.log('error', err);
        },
      }
    );
  };

  const { mutate: mutatePsu, isLoading } = usePostPsuElectionConfig();

  const handleSubmitPsu = (values: any): void => {
    delete values.settlementValidity;
    const payload = {
      ...values,
      // sellAll: checkedList?.includes('sellAll'),
      // sellToCover: checkedList?.includes('sellToCover'),
      // holdToCover: checkedList?.includes('holdToCover'),
      planTypeCategory: psuConfig?.planTypeCategory,
      planTypeCategoryId: psuConfig?.planTypeCategoryId,
      electionWindowConfigurationId: psuConfig?.electionWindowConfigurationId ?? '',
      settlementTypeIds: checkedList.map((id) => ({ settlementTypeId: id })),
    };

    if (_.isEqual(payload, psuConfig)) {
      toast.success(<ToastComponent label='Info' message={'No changes made'} />);
    } else {
      mutatePsu(payload);
    }
  };

  useEffect(() => {
    queryClient.invalidateQueries('getPSUElectionWindowConfigurationList');
    getDefaultPsu();
  }, [refresher, initialOptions]);

  return (
    <ElectionWindowWrapper>
      <div className='flex gap-x-5 mb-2'>
        <Heading
          variant='h4'
          title={'Set Up Tax Settlement Method - PSU'}
          className='election-head'
        />
        <ClickableTooltip
          tooltipText={
            'Set up here if you support different tax settlement options for grant releases and exercises. Select all the methods you want to make available in “Settlement Method” below. Then select the tax settlement election window. If participants do not elect a tax settlement method prior to window closing, they will be set to Company’s default tax settlement method above. Election windows are from each release or exercise event dates.'
          }
          size='sm'
          variant='dark'>
          <span className='material-symbols-sharp' style={MaterialIconFilled}>
            info
          </span>
        </ClickableTooltip>
      </div>

      <ElectionCardBlock>
        {psuLoader && <Spinners />}
        {!psuLoader && (
          <Formik
            initialValues={psuConfig != null ? transformObjectToNull(psuConfig) : {}}
            validationSchema={ELECTION_CONFIGURATION}
            onSubmit={(values) => {
              handleSubmitPsu(values);
            }}>
            {({ touched, errors, setFieldValue }) => {
              useEffect(() => {
                setFieldValue('settlementValidity', isEmpty(checkedList) ? true : viewSelected);
              }, [checkedList]);
              return (
                <Form id='psu-form'>
                  <div className='grid lg:grid-cols-12 md:grid-cols-1 gap-x-8 '>
                    <div className='lg:col-span-2 col-span-1 '>
                      <InputText
                        type='days'
                        label='Election Window Starts'
                        name='startFromVestingDateInDays'
                        onChange={(e: any) => {
                          checkNaNValue(
                            e?.target?.value,
                            'startFromVestingDateInDays',
                            setFieldValue
                          );
                        }}
                        errors={errors}
                        touched={touched}
                        required
                      />
                    </div>
                    <div className='lg:col-span-2 col-span-1 '>
                      <InputText
                        type='days'
                        label='Election Window Ends'
                        name='endFromVestingDateInDays'
                        onChange={(e: any) => {
                          checkNaNValue(
                            e?.target?.value,
                            'endFromVestingDateInDays',
                            setFieldValue
                          );
                        }}
                        errors={errors}
                        touched={touched}
                        required
                      />
                    </div>
                    <div className='lg:col-span-4 col-span-1 mb-6'>
                      <ElectionCustomDropdown
                        name='settlementMethod'
                        title='Tax Settlement Methods Available'
                        display={viewSelected}
                        handleUser={(value: string) => {
                          handleCheck(value, setFieldValue);
                        }}
                        data={electionMethod}
                        checkedList={checkedList}
                        errorMessage={errors?.settlementMethod as string}
                        toggleItems={{
                          displaySelected: true,
                        }}
                      />
                    </div>
                  </div>
                  <div>
                    <Button
                      // btnClassName='mt-[24px]'
                      title={`Save`}
                      type='submit'
                      variant={'primary'}
                      loading={isLoading}
                    />
                  </div>
                </Form>
              );
            }}
          </Formik>
        )}
      </ElectionCardBlock>
    </ElectionWindowWrapper>
  );
};

export default PsuElectionConfig;
