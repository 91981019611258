/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Wealthlane_AwardSettlementService_Dtos_ElectionWindowConfiguration_DefaultSettlementConfigDto } from '../models/Wealthlane_AwardSettlementService_Dtos_ElectionWindowConfiguration_DefaultSettlementConfigDto';
import type { Wealthlane_AwardSettlementService_Dtos_ElectionWindowConfiguration_ElectionWindowConfigurationDto } from '../models/Wealthlane_AwardSettlementService_Dtos_ElectionWindowConfiguration_ElectionWindowConfigurationDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class EsopElectionWindowConfigurationService {

    /**
     * @returns Wealthlane_AwardSettlementService_Dtos_ElectionWindowConfiguration_ElectionWindowConfigurationDto Success
     * @throws ApiError
     */
    public static getApiAwardSettlementApiAppESOpElectionWindowConfigurationESOpElectionWindowConfigurations(): CancelablePromise<Wealthlane_AwardSettlementService_Dtos_ElectionWindowConfiguration_ElectionWindowConfigurationDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award-settlement/api/app/e-sOPElection-window-configuration/e-sOPElection-window-configurations',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns Wealthlane_AwardSettlementService_Dtos_ElectionWindowConfiguration_ElectionWindowConfigurationDto Success
     * @throws ApiError
     */
    public static postApiAwardSettlementApiAppESOpElectionWindowConfigurationSaveESopElectionWindowConfiguration(
requestBody?: Wealthlane_AwardSettlementService_Dtos_ElectionWindowConfiguration_ElectionWindowConfigurationDto,
): CancelablePromise<Wealthlane_AwardSettlementService_Dtos_ElectionWindowConfiguration_ElectionWindowConfigurationDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/award-settlement/api/app/e-sOPElection-window-configuration/save-eSOPElection-window-configuration',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns Wealthlane_AwardSettlementService_Dtos_ElectionWindowConfiguration_DefaultSettlementConfigDto Success
     * @throws ApiError
     */
    public static getApiAwardSettlementApiAppESOpElectionWindowConfigurationDefaultSettlementForESop(): CancelablePromise<Array<Wealthlane_AwardSettlementService_Dtos_ElectionWindowConfiguration_DefaultSettlementConfigDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award-settlement/api/app/e-sOPElection-window-configuration/default-settlement-for-eSOP',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns Wealthlane_AwardSettlementService_Dtos_ElectionWindowConfiguration_DefaultSettlementConfigDto Success
     * @throws ApiError
     */
    public static getApiAwardSettlementApiAppESOpElectionWindowConfigurationDefaultSettlementForESopForParticipants(): CancelablePromise<Array<Wealthlane_AwardSettlementService_Dtos_ElectionWindowConfiguration_DefaultSettlementConfigDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/award-settlement/api/app/e-sOPElection-window-configuration/default-settlement-for-eSOPFor-participants',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

}
