import Tag from '../../components/lozenge/tags';
import { ActionButton } from '../../components/vertical-dot/popper';
import { TableBodyCellColumn } from '../style/table.styled';
import { getActionList } from '../../utils/global';
import moment from 'moment';
import { DATE_FORMAT } from '../common';

export const TaxSetupHeader = (actions: any): any[] => {
  const getActions = (rowData: Record<string, any>): any => {
    let newActions = [];
    if (rowData.hasApprover === false && rowData?.statusSystemName === 'Created') {
      newActions = getActionList(actions).filter((item: any) =>
        ['View', 'Individual Tax', 'Edit', 'Make a Copy', 'Activate', 'Delete'].includes(item.type)
      );
    } else if (rowData.hasApprover === true && rowData?.statusSystemName === 'Created') {
      newActions = getActionList(actions).filter((item: any) =>
        [
          'View',
          'Individual Tax',
          'Edit',
          'Make a Copy',
          'Request for Approval',
          'Delete',
        ].includes(item.type)
      );
    } else if (
      rowData.statusSystemName === 'Approved' &&
      rowData.equityCompensationLevelName === 'Company'
    ) {
      newActions = getActionList(actions).filter((item: any) =>
        ['View', 'Individual Tax', 'Make a Copy', 'Override Tax', 'Deactivate'].includes(item.type)
      );
    } else if (rowData.statusSystemName === 'Approved') {
      newActions = getActionList(actions).filter((item: any) =>
        ['View', 'Individual Tax', 'Make a Copy', 'Deactivate'].includes(item.type)
      );
    } else if (
      rowData.statusSystemName === 'Rejected' ||
      rowData.statusSystemName === 'Deactivated'
    ) {
      newActions = getActionList(actions).filter((item: any) =>
        ['View', 'Individual Tax', 'Make a Copy', 'View Comment'].includes(item.action)
      );
    } else {
      newActions = getActionList(actions).filter((item: any) =>
        ['View', 'Individual Tax', 'Make a Copy'].includes(item.type)
      );
    }
    return newActions;
  };

  return [
    {
      Header: 'Tax Period',
      accessor: 'taxPeriodName',
      width: 300,
      Cell: ({ row }: any) => {
        return (
          <TableBodyCellColumn>
            <TableBodyCellColumn>
              <div className='cellTitle'>
                {row.original.taxPeriodName != null && row.original.taxPeriodName !== ''
                  ? row.original.taxPeriodName
                  : '-'}
              </div>
              <div className='cellTitle'>
                {`(${moment(row?.original?.taxEffectiveFrom).format(DATE_FORMAT)} -
                  ${moment(row.original?.taxEffectiveTo).format(DATE_FORMAT)})
                `}
              </div>
            </TableBodyCellColumn>
          </TableBodyCellColumn>
        );
      },
    },
    {
      Header: 'Name',
      accessor: 'name',
      width: 300,
      Cell: ({ row }: any) => {
        return (
          <TableBodyCellColumn>
            <div className='cellTitle'>
              {row?.original?.name != null && row?.original?.name !== ''
                ? row?.original?.name
                : '-'}
            </div>
          </TableBodyCellColumn>
        );
      },
    },
    {
      Header: 'Parent Tax',
      accessor: 'parentTaxItemIdName',
      width: 200,
      Cell: ({ row }: any) => {
        return (
          <TableBodyCellColumn>
            {row.original.parentTaxItemIdName != null && row.original.parentTaxItemIdName !== ''
              ? row.original.parentTaxItemIdName
              : '-'}
          </TableBodyCellColumn>
        );
      },
    },
    {
      Header: 'Jurisdiction Level',
      accessor: 'jurisdictionName',
      width: 200,
      Cell: ({ row }: any) => {
        return (
          <TableBodyCellColumn>
            {row.original.jurisdictionName != null && row.original.jurisdictionName !== ''
              ? row.original.jurisdictionName
              : '-'}
          </TableBodyCellColumn>
        );
      },
    },
    {
      Header: 'Applicable To',
      accessor: 'equityCompensationLevelName',
      width: 200,
      Cell: ({ row }: any) => {
        return (
          <TableBodyCellColumn>
            {row.original.equityCompensationLevelName != null &&
            row.original.equityCompensationLevelName !== ''
              ? row.original.equityCompensationLevelName
              : '-'}
          </TableBodyCellColumn>
        );
      },
    },
    {
      Header: 'Income YTD Type',
      accessor: 'incomeYTDTypeSystemName',
      width: 200,
      Cell: ({ row }: any) => {
        return (
          <TableBodyCellColumn>
            {row.original.incomeYTDTypeSystemName != null &&
            row.original.incomeYTDTypeSystemName !== ''
              ? row.original.incomeYTDTypeSystemName
              : '-'}
          </TableBodyCellColumn>
        );
      },
    },

    {
      Header: 'Status',
      accessor: 'statusSystemName',
      width: 300,
      Cell: ({ row }: any) => (
        <Tag
          variant={
            row.original.statusSystemName === 'Approved'
              ? 'success'
              : row.original.statusSystemName === 'Rejected' ||
                row.original.statusSystemName === 'Deactivated'
              ? 'error'
              : row.original.statusSystemName === 'Created'
              ? 'primary'
              : 'info'
          }
          title={row.original.statusDisplayName.toUpperCase()}
        />
      ),
    },
    {
      Header: '',
      accessor: 'actions',
      width: 20,
      sortable: false,
      Cell: ({ row }: any) => (
        <ActionButton
          actions={getActions(row.original)}
          id={row.original.id}
          rowData={row.original}
          counter={parseInt(row?.id)}
        />
      ),
    },
  ];
};
