/* eslint-disable camelcase */
import { useState } from 'react';
import { useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import AnimationWrapper from '../../../components/animation/animationWrapper';
import Button from '../../../components/button';
import ConfirmationModal from '../../../components/modal/confirmationModal';
import Search from '../../../components/search/search';
import Table from '../../../components/table';
import ToastComponent from '../../../components/toast';
import Heading from '../../../components/typography';
import InitialValuesForm from '../../../constants/initialValues';
import { employeeTypesHeader } from '../../../constants/table-headers/employeeTypesHeader';
import {
  useBoolean,
  useGetEmployeeTypesListWithPagination,
  useWealthlaneCustomerApi,
} from '../../../hooks';
import {
  EmployeeTypeService,
  type Wealthlane_CustomerService_Dtos_EmployeeType_EmployeeTypeDto,
} from '../../../services/wealthlane-customer-services';
import { type ModalStatusType, type TableParamsType } from '../../../types/common';
import { PageHeader } from '../company-profile/profile.styled';
import { SettingRoleButton } from '../system-roles/systemRoleStyled';
import EmployeeTypeModal from './employeeTypeModal';
import Filter from '../../../components/filter';
import FormSelect from '../../../components/dropdown/formSelect';
import { EnableBoolean } from '../../../constants/common';
import { ResetFilterWrapper } from '../../../constants/style/common.styled';

const employeeTypesState = {
  data: InitialValuesForm.employeeTypesForm,
  id: '',
  modalStatus: 'Add' as ModalStatusType,
  loading: false,
  searchQuery: '',
};

const EmployeeTypesComponent = (): JSX.Element => {
  const [employeeTypesId, setEmployeeTypesId] = useState('');
  const [employeeTypesInfo, setEmployeeTypesInfo] = useState(employeeTypesState);
  const [tableInfo, setTableInfo] = useState<TableParamsType>(InitialValuesForm.tableParams);
  const { value: isModalOpen, setFalse: closeModal, setTrue: openModal } = useBoolean(false);
  const {
    value: isConfirmModalOpen,
    setFalse: closeConfirmModal,
    setTrue: openConfirmModal,
  } = useBoolean(false);

  // GET LIST
  const queryClient = useQueryClient();
  const { handleRequest } = useWealthlaneCustomerApi();
  const { data, status } = useGetEmployeeTypesListWithPagination(handleRequest, tableInfo);
  const [open, setOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');

  // TABLE ACTION
  const actions = [
    {
      action: 'View',
      onClick: (id: string) => {
        getEmployeeTypesData(id, 'View');
      },
    },
    {
      action: 'Edit',
      as: 'Edit',
      onClick: (id: string) => {
        getEmployeeTypesData(id, 'Edit');
      },
    },
    {
      action: <div className='delete-label'>Delete</div>,
      as: 'Delete',
      loading: employeeTypesInfo.loading,
      onClick: (id: string) => {
        setEmployeeTypesId(id);
        openConfirmModal();
      },
    },
  ];

  const getEmployeeTypesData = (id: string, type: ModalStatusType): void => {
    handleRequest(EmployeeTypeService.getApiCustomerApiAppEmployeeTypeEmployeeType(id))
      .then((data?: Wealthlane_CustomerService_Dtos_EmployeeType_EmployeeTypeDto) => {
        if (data != null) {
          setEmployeeTypesInfo({
            ...employeeTypesInfo,
            modalStatus: type,
            id,
            data: {
              name: data.name ?? '',
              description: data.description ?? '',
              isTaxEnable: data.isTaxEnable ?? false,
            },
          });
          openModal();
        }
      })
      .catch((error: any) => {
        console.log('error', error);
      });
  };

  const deleteEmployeeTypesData = (): void => {
    setEmployeeTypesInfo({ ...employeeTypesInfo, loading: true });
    handleRequest(
      EmployeeTypeService.deleteApiCustomerApiAppEmployeeTypeEmployeeType(employeeTypesId)
    )
      .then((data?) => {
        if (data != null) {
          setEmployeeTypesInfo({ ...employeeTypesInfo, loading: false });
          toast.success(<ToastComponent label='Success' message='Employee Types Deleted.' />);
          void queryClient.invalidateQueries('getEmployeeTypesListWithPagination');
        }
      })
      .catch((error: any) => {
        console.log('error', error);
      })
      .finally(() => {
        setEmployeeTypesInfo({ ...employeeTypesInfo, loading: false });
        closeConfirmModal();
      });
  };

  return (
    <>
      <PageHeader>
        <Heading variant='h3' title='Employee Types' />
        <Heading
          variant='body-s'
          title={`Current list of Wealthlane system access department for`}
        />
      </PageHeader>
      <div className='grid sm:grid-cols-12 justify-between gap-4 sm:gap-4'>
        <div className='lg:col-span-3 col-span-6'>
          <Search
            placeholder='Search'
            searchValue={employeeTypesInfo.searchQuery}
            onSearch={(value: string) => {
              setEmployeeTypesInfo({
                ...employeeTypesInfo,
                searchQuery: value,
              });
            }}
            setTableInfo={setTableInfo}
            tableInfo={tableInfo}
          />
        </div>{' '}
        <div className='lg:col-span-3 flex gap-4'>
          <Filter setOpen={setOpen} open={open} className='block'>
            <Heading variant='body-xs' title={'Filter By:'} className={'filter-label'} />
            <div className='grid sm:grid-cols-2 gap-x-5'>
              <FormSelect
                type='text'
                name='enableTaxStatus'
                placeholder='Tax Status'
                onChange={(value: boolean) => {
                  setTableInfo({ ...tableInfo, isTaxEnable: value });
                }}
                options={EnableBoolean ?? []}
                values={tableInfo.isTaxEnable}
                arrValue={tableInfo.isTaxEnable}
                containerClassName='formGroup mb-0'
                multiple={false}
                isFormik={false}
              />
            </div>
          </Filter>
          <div>
            <ResetFilterWrapper
              onClick={() => {
                setTableInfo(InitialValuesForm.tableParams);
                setSearchQuery('');
              }}>
              <span className='material-symbols-outlined'>settings_backup_restore</span>
              <Heading variant='body-s' title='Reset Filter' />
            </ResetFilterWrapper>
          </div>
        </div>
        <SettingRoleButton className='lg:col-start-10 lg:col-span-3 col-span-6'>
          <Button
            as='Create'
            variant={'primary'}
            title={'Add Employee Type'}
            icon={'add_circle_outline'}
            onClick={() => {
              setEmployeeTypesInfo({
                ...employeeTypesInfo,
                data: InitialValuesForm.employeeTypesForm,
                modalStatus: 'Add',
                id: '',
              });
              openModal();
            }}
          />
        </SettingRoleButton>
        <AnimationWrapper>
          {isModalOpen && (
            <EmployeeTypeModal
              isModalOpen={isModalOpen}
              closeModal={closeModal}
              initialData={employeeTypesInfo}
              handleRequest={handleRequest}
              setModalStatus={(value) => {
                setEmployeeTypesInfo({
                  ...employeeTypesInfo,
                  modalStatus: value,
                });
              }}
              loading={employeeTypesInfo.loading}
            />
          )}

          {isConfirmModalOpen && (
            <ConfirmationModal
              isShow={isConfirmModalOpen}
              handleClose={() => {
                closeConfirmModal();
              }}
              title='employee type'
              handleSubmit={() => {
                deleteEmployeeTypesData();
              }}
              loading={employeeTypesInfo.loading}
            />
          )}
        </AnimationWrapper>
      </div>

      <Table
        columns={employeeTypesHeader(actions)}
        data={data?.items ?? []}
        loading={status}
        showPagination
        totalCounts={data?.totalCount ?? 10}
        tableInfo={tableInfo}
        setTableInfo={setTableInfo}
      />
    </>
  );
};

export default EmployeeTypesComponent;
