import { type UseQueryResult, useQuery } from 'react-query';
import { EmployeeTypeService } from '../../services/wealthlane-customer-services';
import { TaxItemService } from '../../services/wealthlane-tax-services';

const useGetIncomeYtdTypes = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>
): UseQueryResult<
  | Array<{
      label: string;
      value: string;
    }>
  | undefined,
  unknown
> => {
  const incomeYtdTypes = useQuery(
    ['getIncomeYtdTypes'],
    async () => {
      return await handleRequest(TaxItemService.getApiTaxApiAppTaxItemIncomeYTdTypes());
    },
    {
      select: (data) => {
        return data?.map((item) => {
          return {
            label: item.name ?? '',
            value: item.id ?? '',
          };
        });
      },
    }
  );

  return incomeYtdTypes;
};

export default useGetIncomeYtdTypes;
