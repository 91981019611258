import React, { useState, useRef, useEffect } from 'react';
import Styled from 'styled-components';
import Button from '../../button/index'; // Make sure to provide the correct path
import useWealthlaneCustomerApi from '../../../hooks/useWealthlaneCustomerApi';
import useGetCustomerUserDetails from '../../../hooks/get/useGetCustomerUserDetails';

const ChatModalContainer = Styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 500px;
  height: 600px;
  position: fixed;
  top: 50%;
  right: -10%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1000;
`;

const InputModuleWrapper = Styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;



const Input = Styled.textarea`
  border: 1px solid lightgray;
  border-radius: 4px;
  flex-grow: 1;
  margin-right: 10px;
  padding: 6px 12px; // Adjust padding as needed
  font-size: 16px; // Match your design
  line-height: 1.5; // For better readability
  // resize: none; // Prevent manual resizing
  overflow-y: auto; // Show scrollbar when content overflows
  min-height: 30px; // Minimum height to match your design
  height: 30px;
  max-height: 100px; // Maximum height before scrollbar appears
`;
const ResponseArea = Styled.div`
  flex-grow: 1;
  overflow-y: auto; // Make it scrollable
  padding: 10px;
  margin-bottom: 10px; // Space above the input module
  background-color: rgba(210,210,210,0.1);
`;

const ChatButton = Styled(Button)`
  height: 30px;
  width: 35px;
`;

const VerticalSpacer = Styled.div`
  height: 20px;
`;

interface ChatMessage {
  sender: string;
  message: string;
}

const ChatModal: React.FC<{ closeModal: () => void }> = ({ closeModal }) => {
  const [inputText, setInputText] = useState('');
  const [chatHistory, setChatHistory] = useState<ChatMessage[]>([]);
  const [loading, setLoading] = useState(false);
  const responseContainerRef = useRef<HTMLDivElement | null>(null); // Initialize with null
  const { handleRequest: customerName } = useWealthlaneCustomerApi();
  const { data: userName } = useGetCustomerUserDetails(customerName, true); //pass tre as the second parameter for now

  // Scroll to the bottom of the ResponseArea
  const scrollToBottom = () => {
    if (responseContainerRef.current) {
      responseContainerRef.current.scrollTop = responseContainerRef.current.scrollHeight;
    }
  };

  useEffect(scrollToBottom, [chatHistory]);

  const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setInputText(e.target.value);
  };

  const handleApiCall = async () => {
    setLoading(true);
    const newChatHistory = [...chatHistory, { sender: 'You', message: inputText }];
    setChatHistory(newChatHistory);

    let sessionId = getOrCreateSessionID();
    

    try {
      const response = await fetch('https://rv407ijcif.execute-api.us-east-1.amazonaws.com/TestWithHeaders/lexBot', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': '9Dkyu1Y6jB83fbHm7Uxka6vlRjqglq934KAbyip9',
          'x-user-id': userName.firstName,
          'x-employer-id': userName.customerUserId,
          'x-session-id': sessionId
          // 'x-user-id': 'user',
          // 'x-employer-id': 'empl',
          // 'x-session-id': 'sid'
        },
        body: JSON.stringify({ inputText }),
      });

      if (response.ok) {
        const data = await response.json();
        const firstMessageContent = data.messages[0]?.content || 'No response available.';
        setChatHistory([...newChatHistory, { sender: 'Wealthlane', message: firstMessageContent }]);
        scrollToBottom(); // Scroll after setting the chat history
      } else {
        setChatHistory([...newChatHistory, { sender: 'Wealthlane', message: 'Error fetching data. Please try again.' }]);
      }
    } catch (error) {
      setChatHistory([...newChatHistory, { sender: 'Wealthlane', message: 'An error occurred. Please try again later.' }]);
      console.error('Fetch error:', error);
    }
    setLoading(false);
    setInputText(''); // Clear the input field
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter' && !loading) {
      e.preventDefault(); // Prevent form submission
      handleApiCall();
    }
  };

  function getOrCreateSessionID() {
    let my_sessionId = sessionStorage.getItem('chat-session-id');
    if (!my_sessionId) {
      // Generate a GUID (UUID) for the session ID
      const guid = generateGUID();
      
      // Save the session ID in sessionStorage
      sessionStorage.setItem('chat-session-id', guid);
    }
    return my_sessionId;
  }

  // Function to generate a GUID (UUID)
  function generateGUID() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      const r = (Math.random() * 16) | 0;
      const v = c === 'x' ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  }
  return (
    <ChatModalContainer>
        <button onClick={() => {
          sessionStorage.removeItem('chat-session-id');
          closeModal(); // Close the modal
        }} style={{ position: 'absolute', top: '10px', right: '10px', fontSize: '16px' }}>X</button>
      <VerticalSpacer/>
      <ResponseArea ref={responseContainerRef}>
        {chatHistory.map((chat, index) => (
          <p key={index}><strong>{chat.sender}:</strong> {chat.message}<br/></p>
        ))}
      </ResponseArea>
      <InputModuleWrapper>
      <Input
      value={inputText}
      onChange={handleInputChange}
      onKeyPress={handleKeyPress}
      placeholder="Type your question..."
      disabled={loading}
    />
        <ChatButton
          icon='send'
          variant="primary"
          title={loading ? '' : ''}
          onClick={handleApiCall}
          disabled={loading}
          type="button"
        />
      </InputModuleWrapper>
    </ChatModalContainer>
  );
};

export default ChatModal;
