import { useState, useRef, useEffect } from 'react';
import { ElectionDropdownContainer } from './customDropdown.style';
import { TableBodyCellColumn } from '../../constants/style/table.styled';
import { RadioButtonWrapper } from '../radio-button/radioButton.styled';
import Spinners from '../spinner';
import FlashMessage from '../flash-message';
import { DropdownType, type UserType } from '../../types/common';
import { ErrorMessage } from 'formik';
import { isEmpty } from 'lodash';
import Lozenge from '../lozenge';

interface CustomDropdownType {
  title: string;
  name: string;
  data?: DropdownType[];
  checkedList: string[];
  loading?: boolean;
  handleUser: (value: string) => void;
  toggleItems?: Record<string, string | number | boolean>;
  handleAllCheck?: any;
  errorMessage?: string;
  display?: boolean;
}

const ElectionCustomDropdown = ({
  title,
  name,
  data,
  loading,
  checkedList = [],
  handleUser,
  toggleItems,
  errorMessage = '',
  display,
}: CustomDropdownType): JSX.Element => {
  const [approvalUserList, setApprovalUserList] = useState<DropdownType[] | undefined>([]);
  const [show, setShow] = useState(false);

  const [toggleData, setToggleData] = useState<Record<string, string | number | boolean>>({
    showSearch: true,
    showTotal: true,
    showCheckbox: true,
    showErrorMessage: true,
    close: false,
  });

  useEffect(() => {
    if (data != null) {
      setApprovalUserList(data);
    }
  }, [data]);

  useEffect(() => {
    setToggleData({ ...toggleData, ...toggleItems });
  }, [toggleItems]);

  const handleShow = (): void => {
    setShow(!show);
  };
  const wrapperRef = useRef<any>(null);
  useEffect(() => {
    function handleClickOutside(event: Event): void {
      if (
        wrapperRef.current != null &&
        !(wrapperRef.current.contains(event.target as Node) as boolean)
      ) {
        setShow(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [wrapperRef]);

  return (
    <div className='formField'>
      <ElectionDropdownContainer className={`${show ? 'active' : ''}`}>
        <div className={`dropdownContainer ${show ? 'active' : ''} `} ref={wrapperRef}>
          <label htmlFor='dropdownId'>{title}</label>
          <button
            type='button'
            className={`dropdownButton ${errorMessage != '' ? 'is-invalid' : ''}`}
            onClick={() => {
              handleShow();
            }}>
            <span
              className={`${
                toggleData?.displaySelected === true && display
                  ? 'placeholderTitle'
                  : 'dropdownTitle'
              }  text-truncate  `}>
              {display
                ? approvalUserList
                    ?.filter((option) => checkedList.includes(option?.value))
                    ?.map((mapped) => mapped.systemName)
                    .join(', ')
                : 'Select Tax Settlement Method'}
            </span>
            <span className='dropdown-arrow'>
              <span className='material-symbols-rounded'>arrow_drop_down</span>
            </span>
          </button>
          <div className='dropdownListContainer'>
            <div className={'dropdownSearchContainer'}>
              {loading && <Spinners />}
              <div className='grid sm:grid-cols-1 md:grid-cols-1 gap-y-6 gap-x-5'>
                {!loading &&
                  approvalUserList?.map((item, index) => (
                    <div className='dropdownSearchItem' key={index}>
                      <RadioButtonWrapper
                        label={item.label as string}
                        className='checkboxWrapper'
                        onClick={() => {
                          handleUser(item?.value);
                        }}>
                        <input
                          type='checkbox'
                          id={item.value}
                          checked={checkedList?.includes(item?.value)}
                        />
                      </RadioButtonWrapper>

                      <label
                        className={`dropdownSearchItem-label `}
                        onClick={() => {
                          handleUser(item?.value);
                        }}>
                        <TableBodyCellColumn>
                          <div className='cellTitle'>{item.label}</div>
                        </TableBodyCellColumn>
                      </label>
                    </div>
                  ))}
              </div>
              {!(loading ?? false) && approvalUserList != null && approvalUserList.length === 0 && (
                <FlashMessage size='md' message='No Data Found' type='info' />
              )}
            </div>
          </div>
          {errorMessage && <div className='error-message'>{errorMessage}</div>}
        </div>
      </ElectionDropdownContainer>
    </div>
  );
};

export default ElectionCustomDropdown;
